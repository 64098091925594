import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { MainUrl, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import ContactUS from '../../ContactUS';
// import ForgetPasswordComponent from './ForgetPassword';

export default function HomeScreenLogin() {
	const [userName, setUserName] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [PhoneNumber, setPhoneNumer] = React.useState("")
	const LoginUser = useSelector((state) => state.LoginState);

	const [reload, setReload] = React.useState(false)
	const [passwordShow, setPasswordShow] = React.useState(false)
	const [AlertShow, setAlertShow] = React.useState(false)
	const [Alertcomp, setAlertcomp] = React.useState("")
	const [Register, setRegister] = React.useState(false)
	const [loginSuccess, setLoginSuccess] = React.useState(false)
	const [forgetPasswordPressed, setForgetPasswordPressed] = React.useState(false)
	const BookView = ({ image, title, linkURL }) => {
		return (
			<Link className="Link" to={linkURL}>
				<div className='center' style={{ cursor: "pointer" }}
				>
					<img src={image} className="image-home"
					/>
					<br />
					<span className="text-home" style={{ fontWeight: "bold", textAlign: "center" }}>{title}</span>
				</div>
			</Link >

		)
	}

	return (
		<div style={{ backgroundColor: "#f2f3f2", minHeight: "100vh", width: "100vw", overflow: 'hidden' }}>

			{/* {loginSuccess &&
          <Navigate to="/" />
        } */}
			<div className=""></div>
			<div className="shadow signin-container" style={{ backgroundColor: "#fff" }}>
				<div className="py-5">
					{/* <div style={{ display: 'flex', justifyContent: 'center', padding: "0 0 50px" }}>
						<img
							src={"https://api.skubt.com/wp-content/uploads/2023/12/banner-3.png"}
							height={"200px"}
							width={"100%"}
							style={{ objectFit: "contain" }}
						/>
					</div> */}
					<div className='row'>
						<div className="col-4" style={{ padding: "0 30px" }}>
							<BookView
								title={"Basic Tools"}
								linkURL={`${slugs.books}/basic-tools`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/hangul-2.png"}
							/>
						</div>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Topic Meaning"}
								linkURL={`${slugs.books}/topic-meaning`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/17.png"}
							/>
						</div>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Important Meaning"}
								linkURL={`${slugs.books}/important-meaning`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/9.png"}
							/>
						</div>


					</div>
					<div className='row'>
						<div className="col-4" style={{ padding: "0 30px" }}>
							<BookView
								title={"Grammer"}
								linkURL={`${slugs.books}/grammar`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/8.png"}
							/>
						</div>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Chapter Meaning"}
								linkURL={`${slugs.books}/chapter-meaning`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/18.png"}
							/>
						</div>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Text Book"}
								linkURL={`${slugs.books}/text-book`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/10.png"}
							/>
						</div>
					</div>
					<div className='row'>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Modal Question"}
								linkURL={`${slugs.books}/modal-question`}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/12.png"}
							/>
						</div>
						<div className="col-4" style={{ padding: "0 30px" }}>
							{/* <BookView
								title={"UBT EXAM"}
								linkURL={slugs.demo_exam}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/14.png"}
							/> */}
							<Link className="Link" to={LoginUser.token == null ? "/login" : `${slugs.exam}`}>
								<div className='center' style={{ cursor: "pointer" }}
								>
									<img src={"https://api.skubt.com/wp-content/uploads/2023/12/14.png"} className="image-home"
									/>
									<br />

									<span className="text-home" style={{ fontWeight: "bold", textAlign: "center" }}>
										{LoginUser.token == null? "Login" : "UBT EXAM"}</span>
								</div>
							</Link >

						</div>
						<div className="col-4" style={{ padding: "0 20px" }}>
							<BookView
								title={"Chapter Exam"}
								linkURL={slugs.demo_exam}
								image={"https://api.skubt.com/wp-content/uploads/2023/12/13.png"}
							/>
						</div>

					</div>
				</div>
			</div>
			{/* <ContactUS/> */}
			{reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}

		</div>
	)
}