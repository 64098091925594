import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import PicturesUpload from "./MediaArrayUpload";

export default function ImageUploadMain(props) {

  const history = useHistory()
  const [ID, setID] = React.useState("629")


  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        picture_url_list: JSON.stringify(ImageArray)
      }
    }
    AdsPUTApi(data, "image_home", ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        console.log(err)
        console.warn(err.response.data)
        console.log("Response Status: hello11", err.response.status);
        console.log("Response Headers:", err.response.headers);
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta" }, "image_home")
      .then((res) => {
        if (res.data.length !== 0)
          if (res.data[0].meta.picture_url_list.length !== 0)
            setImageArray(JSON.parse(res.data[0].meta.picture_url_list))
        setImageArrayReload(true)
      })
  }
  const pdfURL = '1-5.pdf';

  return (
    <div>
      <div>
        <div className="row" style={{ padding: "30px 30px" }}>
          <div className="col-md-8 button">
            <h2>App Home Page Image </h2>
          </div>
          <div className="col-md-4" style={{ textAlign: 'right' }}>
            <a href="/">Dashboard</a> / <a href={slugs.all_books}>All Image</a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Pictures Upload</center></h4>
            <table>
              <tbody>
                <tr>
                  <PicturesUpload
                    ImageArray={ImageArray}
                    setImageArray={setImageArray}
                    ImageArrayReload={ImageArrayReload}
                    setImageArrayReload={setImageArrayReload}
                  />
                </tr>
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Images
                </div>
              </div>
            </div>
          </div>
        </div>

      

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
    </div >
  )

}

// import React, { useState } from 'react';
// import { Document, Page,pdfjs } from 'react-pdf';

// import samplePDF from './test.pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/TextLayer.css';

// export default function Test() {
//   const [numPages, setNumPages] = useState(null);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


//   return (
//     <Document
//       // file={samplePDF}
//       // file={"https://api.skubt.com/wp-content/uploads/2023/12/1-3-1.pdf"}
//       file={{url:"https://api.skubt.com/wp-content/uploads/2023/12/1-3-1.pdf"}}
//       onLoadSuccess={onDocumentLoadSuccess}
//       onLoadError={(e)=>{console.log(e)}}
//     >
//       {Array.from(
//         new Array(numPages),
//         (el, index) => (
//           <Page
//             key={`page_${index + 1}`}
//             pageNumber={index + 1}
//           />
//         ),
//       )}
//     </Document>
//   );
// }
