
import React, { Component, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { getDocument } from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import axios from 'axios';
// GlobalWorkerOptions.workerSrc = window.location.origin + '/pdfjs/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



export default function PDFReader() {
  // state = {
  //   numPages: null,
  //   pageNumber: 1
  // };



  // const { pageNumber, numPages } = this.state;
  const [pageNumber, setpageNumber] = React.useState(null);
  const [pdfUrl, setpdfUrl] = React.useState("")
  const [reload, setReload] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  useEffect(() => {
    // axios({
    //   method: "GET",
    //   url: `http://localhost:4000/start-exam-1?pdfurl=https://api.skubt.com/wp-content/uploads/2024/01/model-question-part-2.pdf`,
    // })
    //   .then(res => {
    //     console.log(res.data)
    //     setpdfUrl(res.data.exam_data)
    //     setReload(true)
    //   })
  }, [])
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const PageView = ({ index }) => {
    // useEffect(() => {
    //   setpageNumber(index)
    // }, [])
    return (
      <div>
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        {/* <div style={{textAlign:"center"}}>{index}</div> */}
      </div>
    )
  }
  return (
    <div>
      {/* <Document
        file={`data:application/pdf;base64,${pdfUrl}`}
        // file={"https://api.skubt.com/wp-content/uploads/2023/12/1-3-1.pdf" }
        // file="http://www.pdf995.com/samples/pdf.pdf"
        onLoadSuccess={onDocumentLoadSuccess()}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p> */}
      {/* Page {pageNumber} of {numPages} */}
      {/* <object data="http://africau.edu/images/default/sample.pdf" type="application/pdf" width="100%" height="1000px">
        <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
      </object> */}

      <embed src="http://africau.edu/images/default/sample.pdf" height="100%" width="100%"/>


     
    </div>
  );

}


