import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { AdsGETApi, sideBarImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function AllBooksView(props) {
  const { navigation } = props;
  // const { type, title } = props.route.params;
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamList, setAllExamList] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [AllExamListUnavailable1, setAllExamListUnavailable1] = React.useState([]);
  const [VerifiedvExams, setVerifiedExam] = React.useState([]);
  const [FreeBooks, setFreeBooks] = React.useState([]);
  const location = useLocation()
  var type = location.pathname.slice(slugs.books.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)

  const groups = {
    "basic-tools": "Basic Tools",
    "topic-meaning": "Topic Meaning",
    "important-meaning": "Important Meaning",
    "grammar": "Grammer",
    "chapter-meaning": "Chapter Meaning",
    "text-book": "Text Book",
    "modal-question": "Modal Question"
  }

  const fetchExam = (perPage,) => {
    setReload(true)
    const date = new Date()
    AdsGETApi({
      ...perPage,
    }, slugs.db_slug_book)
      .then((res) => {
        setReload(false)
        setAllExamList1(res.data)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    var type;
    type = location.pathname.slice(slugs.books.length + 1) == "" ? "basic-tools" : location.pathname.slice(slugs.books.length + 1)
    fetchExam({
      per_page: 1000,
      order: "asc",
      _fields: "id,title,meta,slug",
      "filter[meta_query][2][key]": "group",
      "filter[meta_query][2][value][0]": `${groups[type]}`,
      "filter[meta_query][2][compare]": "=",
    }
    )
  }, [])

  return (
    // <SafeAreaView>
    //   <Homeheader navigation={props.navigation} />

    //   <ScrollView style={{ backgroundColor: "#fff", height: height * 0.925 }}>
    //     <View>
    //       {/* <View style={{ margin: 10, padding: 10, borderBottomWidth: 1, flexDirection: 'row' }}> */}
    //       <View style={{  padding: 10,backgroundColor:"#ff0" }}>
    //         <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>{title}</Text>
    //       </View >
    //       <View style={{ margin: width * 0.01, flexDirection: "row" }}>
    //         <View style={{ width: "50%" }}>
    //           {AllExamList1.map((item, index) => (
    //             <View key={index}>
    //               <BookView item={item} navigation={navigation} />
    //             </View>
    //           ))}
    //         </View>
    //       </View>
    //     </View>
    //     {/* <Contactus /> */}
    //     <View style={{ height: height * 0.1 }} />
    //     {reload &&
    //       <View style={{ padding: "50%" }}>
    //         <ActivityIndicator size={height * 0.04} color={"#000"} />
    //       </View>
    //     }
    //   </ScrollView>
    // </SafeAreaView>
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className="col-sm-2 col-12"></div>
          <div className="col-sm-8 col-12 form-view m-3">

            <div className="row">
              {AllExamList1.map((item, index) => (
                <div key={index} className="col-sm-3">
                  <Link to={`${slugs.book_details}/${item.slug}`}>
                    <div style={{
                      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                      borderRadius: "5px", color: "#000"
                    }}>
                      {/* <BookView item={item} navigation={navigation} /> */}
                      {item.title.rendered}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

